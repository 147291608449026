<template>
<v-row class="fill-height">
    <v-col>
        <v-sheet height="64">
            <v-toolbar flat>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-col lg="3" md="3" cols="6" class="s-col-form">
                    <s-select-definition clearable label="Estados" noDefault v-model="filter.ReqStatus" :def="1208" />
                </v-col>
                <v-col lg="3" md="3" cols="6" class="s-col-form">
                    <c-select-area full clearable noDefault v-model="filter.AreID" label="Área" />
                </v-col>
            </v-toolbar>

        </v-sheet>
        <v-sheet height="auto">
            <v-calendar locale="es" ref="calendar" v-model="value" color="primary" @click:event="showEvent" :start="start" :end="end" @moved="moved">
                <template #day="{ past, date}">

                    <v-card style="padding-bottom:12px;" v-for="d in requiremennDay(date)" elevation="0" :key="d.ReqDocumentNumber">
                        <div v-for="(item, index) in Object.keys(d.items)" :key="index">
                            <div style="color:;background: antiquewhite;">
                                <v-row style="margin:auto">
                                    <h4 class="ml-3">{{item}}</h4><span>
                                    </span>
                                </v-row>
                            </div>
                            <!-- <v-hover v-slot="{ hover }" style="margin-top:20px" > :class="{ 'pt-1 pb-1 pr-2 pl-2 mb-2 on-hover': hover }"-->
                            <v-card v-for="m in d.items[item]" dense outlined style="border-radius: 6px;" class="mb-1">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h4 style="margin-bottom: 5px;margin-top:-10px;" class="text-center">
                                                <b style="text-decoration:underline"><a @click="openDialog(m)">Solicitud Nro.: {{ m.ReqDocumentNumber }}</a></b>
                                                <!-- <b v-if="m.ReqNroContract !==null"> | Contrato Nro.: {{m.ReqNroContract}}

                                                </b> -->

                                            </h4>
                                            <v-divider> </v-divider>
                                            <h4 v-if="m.ReqReason !== null" class="text--primary text-center" style="margin-bottom: 1px">
                                                <span>{{ m.ReqReason }}</span> <!-- <span v-if="m.LengthDescriptionCtrc> m.LengthDescriptionCtrcExtract">........</span><br> -->

                                            </h4>
                                            <!--  <v-divider> </v-divider>
                                            <h4 class="text--primary text-center" style="margin-bottom: 1px">
                                                {{m.TypeProcessName}}<br>
                                                {{m.TypeGenerationName}}
                                            </h4> -->
                                            <v-divider> </v-divider>
                                            <v-divider> </v-divider>
                                            <h4 class="text--primary text-center">
                                                Fecha Entrega
                                            </h4>
                                            <br>
                                            <h1 class="text--primary text-center">
                                                <b style="color:brown" v-if="m.ReqDateDeliver !== null">{{$moment(m.ReqDateDeliver).format($const.FormatDateDB)}}</b>
                                            </h1>
                                            <v-row style="margin-top:0px">
                                                <v-col cols="12" class="text-center">
                                                    Tiempo Restante
                                                    <h1 style="transform: scale(0.8);">{{m.ReqTimeLeft}}</h1>
                                                </v-col>
                                                <!-- <v-col cols="6" class="text-center">
                                                    Tiempo Transcurrido
                                                    <h1 style="transform: scale(0.8);">{{m.ReqTimeElapsed}}</h1>
                                                </v-col> -->
                                            </v-row>
                                            <v-divider> </v-divider>
                                            <v-divider> </v-divider>
                                            <v-card elevation="0" dense class="mt-1">

                                                <b>Solicitado por:</b> <br>{{m.UsrName}}
                                                <br><b>Area:</b> {{m.AreName}}
                                                <br><b>Prioridad:</b>
                                                <!-- <v-chip x-small :color="m.TypePriorityColor">

                                                </v-chip> -->
                                                {{ m.TypePriorityName }}
                                                <br>
                                                <v-chip x-small :color="m.ReqStatusColor"> {{m.ReqStatusName }}
                                                    <v-btn fab x-small @click="openDialog(m)" elevation="0" :color="m.ReqStatusColor"><i class="fas fa-eye"></i></v-btn>
                                                </v-chip>
                                                <v-chip class="mt-1" x-small :color="m.ReqProcessInitiated == null && m.ReqProcessEnd == null ? 'error' : m.ReqProcessInitiated == 1 && m.ReqProcessEnd == null ? 'info' : m.ReqProcessInitiated == 1 && m.ReqProcessEnd == 1 ? 'success' : ''">
                                                    {{ m.ReqProcessInitiated == null && m.ReqProcessEnd == null ? 'Sin Iniciar' : m.ReqProcessInitiated == 1 && m.ReqProcessEnd == null ? 'Iniciado' : m.ReqProcessInitiated == 1 && m.ReqProcessEnd == 1 ? 'Finalizado' : '' }}

                                                </v-chip> <b v-if="m.UsrNameInitiatedProcess !== null && m.ReqProcessInitiated == 1 && m.ReqProcessEnd == null">por {{m.UsrNameInitiatedProcess}}</b>
                                                <br>

                                            </v-card>
                                            <v-divider> </v-divider>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                            </v-card>
                            <!-- </v-hover> -->
                        </div>
                    </v-card>
                </template>
            </v-calendar>
        </v-sheet>
    </v-col>
    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent width="100%">
        <v-card>
            <mkt-requirement-edit :value="parentID" @close="close()"></mkt-requirement-edit>
        </v-card>
    </v-dialog>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

</v-row>
</template>

<script>
import _sRequirement from "@/services/Marketing/MktRequirementService";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import MktRequirementEdit from "@/views/Marketing/Requirement/MktRequirementEdit";
import _sGeneral from "@/services/HelperService.js";

export default {
    components: {
        ApprovalLevelsDocument,
        MktRequirementEdit
    },
    data() {
        return {
            processing: false,
            messageProcessing: "",

            dates: [],
            week: 0,
            value: null,
            title: "",
            dialog: false,
            events: [],
            start: null,
            end: null,
            parentID: 0,
            dialogApproval: false,
            dialogEdit: false,
            filter: {
                BeginDate: this.start,
                EndDate: this.end,
                ReqStatus: 4,
                AreID: null
            },
        }
    },

    created() {
        if (!this.$fun.isAdmin()) this.AreID = this.$fun.getUserInfo().CtrContract.AreID;
        this.calendar(this.$moment(new Date()));
    },

    watch: {
        'filter.ReqStatus'() {
            this.calendar(this.$moment(new Date()));
        },
        'filter.AreID'() {
            this.calendar(this.$moment(new Date()));
        }
    },

    methods: {

        openDialog(item) {
            console.log(item)
            this.parentID = item.ReqID
            this.dialogEdit = true
        },

        moved({
            date
        }) {
            this.calendar(date);
        },

        requiremennDay(date) {
            return this.dates.filter((x) => {
                return x.start === date;
            });
        },

        calendar(date) {

            this.dates = [];
            this.title = this.$moment(date)
                .format("MMMM")
                .toUpperCase();
            this.start = this.$moment(date).format("YYYY-MM-01");
            let days = this.$moment(this.start).daysInMonth();
            this.end = this.$moment(this.start)
                .add(days, "d")
                .format("YYYY-MM-DD");

            let obj = {
                searchText: "",
                dir: "",
                asc: "",
                length: -1,
                filter: this.filter
            };

            _sRequirement.pagination(obj, this.$fun.getUserID()).then((r) => {
                var daysInMonth = this.$moment().daysInMonth() + 6;
                let i = 0;
                while (i <= daysInMonth) {
                    i++;
                    var current = this.$moment(this.start).date(i).format("YYYY-MM-DD");

                    let date = {
                        start: current,
                        items: r.data.data.filter(
                            (x) => this.$moment(x.ReqDate).format("YYYY-MM-DD") == current
                        ),
                    };

                    if (date.items.length > 0) {
                        console.log(date.items)
                        /* date.items.forEach(element => {
                            if (element.ReqReason !== null) {
                                element.ReqReasonExtract = element.ReqReason.substring(0, 70)
                                element.LengthReasonExtract = element.ReqReasonExtract.length
                                element.LengthReason = element.ReqDescriptionCtrc.length
                            }

                        }); */
                    }

                    var newObj = _.groupBy(date.items, "AreName");
                    console.log(newObj)

                    date.items = newObj
                    this.dates.push(date);
                }

            })

        },

        showEvent() {
            return this.dates;
        },

        close() {
            this.dialogEdit = false;
            this.calendar(this.$moment(new Date()));
        },
    },
}
</script>

<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    left: 0;
    justify-content: center;
    opacity: 1;
    width: 100%;
    z-index: 99999;
}
</style>
